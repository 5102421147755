<template>
  <ItemList
    @addItem="addTag"
    @removeItem="removeTag"
    :items="tags"
    :isLoading="isLoadingList"
    noDataText="Nenhuma Tag encontrada"
    label="Etiqueta"
  />
</template>

<script>
export default {
  components: {
    ItemList: () => import('./ItemList.vue'),
  },
  data: () => ({
    tags: [],
    isLoadingList: false,
  }),
  methods: {
    getTags() {
      this.isLoadingList = true
      this.$api
        .get('tags')
        .then((res) => {
          this.tags = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoadingList = false
        })
    },
    addTag(value) {
      this.$api
        .post('tags/store', { name: value })
        .then((res) => {
          this.tags.push(res.data.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    removeTag(item) {
      this.$api
        .post('tag/delete', item)
        .then(() => {
          let idx = this.tags.indexOf(item)
          this.tags.splice(idx, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    if (this.tags.length === 0) {
      this.getTags()
    }
  },
}
</script>

<style>
</style>